




































































import Vue, { PropType } from "vue";
import ActionButton from "@/components/ActionButton.vue";
import UserName from "@/components/UserName.vue";
import UserEmail from "@/components/UserEmail.vue";
import ReferralReactorProduct from "@/components/ReferralReactorProduct.vue";

export default Vue.extend({
  name: "SignupListItem",
  components: {
    ActionButton,
    UserName,
    UserEmail,
    ReferralReactorProduct
  },
  props: {
    signup: { type: Object as PropType<Account.Signup>, required: true }
  },
  computed: {
    hasAccountDetails(): boolean {
      return !!this.signup?.account && Object.keys(this.signup?.account).length > 0;
    },
    hasStartedCheckingOut(): boolean {
      console.log("hasStartedCheckingOut", this.signup?.product, this.signup?.coupon);
      return !!this.signup?.product || !!this.signup?.coupon;
    }
  }
});

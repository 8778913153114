










import Vue from "vue";

export default Vue.extend({
  name: "UserName",
  props: {
    userId: { type: String, required: true },
    fromAccount: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
    normal: { type: Boolean, default: false }
  },
  computed: {
    user(): Account.User | null {
      return this.$store.direct.state.users.items[this.userId] ?? null;
    },
    name(): string | null {
      return (
        `${this.user?.firstName?.trim() ?? ""} ${this.user?.lastName?.trim() ?? ""}`.trim() || null
      );
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler(userId: string) {
        void this.$store.direct.dispatch.watchUser(userId);
      }
    }
  }
});

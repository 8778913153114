










import Vue from "vue";

export default Vue.extend({
  name: "UserEmail",
  props: {
    userId: { type: String, required: true },
    fromAccount: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
    normal: { type: Boolean, default: false }
  },
  computed: {
    user(): Account.User | undefined {
      return this.$store.direct.state.users.items[this.userId];
    },
    email(): string | null {
      return this.user?.email || null;
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler(userId: string): void {
        void this.$store.direct.dispatch.watchUser(userId);
      }
    }
  }
});

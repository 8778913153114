
















import Vue from "vue";
import { Subject } from "rxjs";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";

import SignupListItem from "@/components/SignupListItem.vue";
import ListFirestore from "@/components/ListFirestore.vue";

export default Vue.extend({
  name: "Signups",
  components: {
    ListFirestore,
    SignupListItem
  },
  data: () => ({
    createAccountModalIsOpen: false,
    onReset: new Subject(),
    selectedSortOption: "name"
  }),
  computed: {
    sortOptions() {
      return { mostRecentScanTime: "Last Scan", createdAt: "Date Created", name: "Name" };
    },
    sortDirection() {
      switch (this.selectedSortOption) {
        case "createdAt":
        case "mostRecentScanTime":
          return "desc";
        case "name":
        default:
          return "asc";
      }
    }
  },
  watch: {
    selectedSortOption(): void {
      this.onReset.next(null);
    }
  },
  methods: {
    getSignupsCollection(): FirestoreQuery {
      console.log(this.selectedSortOption, this.sortDirection);
      return db.collection("signups").orderBy("startedAt", "desc");
    },
    orderList(items: Record<string, Account.Signup>): Account.Signup[] {
      return orderBy(Object.values(items), this.selectedSortOption, this.sortDirection);
    }
  }
});

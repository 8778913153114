





import Vue from "vue";
import type { Product } from "@/typings/stripe";

export default Vue.extend({
  name: "ReferralReactorProduct",
  props: {
    productId: { type: String, required: true }
  },
  computed: {
    product(): Product | undefined {
      return this.productId ? this.$store.direct.state.products.items[this.productId] : undefined;
    },
    productLabel(): string | null {
      console.log("Plan Name", this.product);
      return this.product?.metadata?.label || null;
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler(product: string) {
        void this.$store.direct.dispatch.watchProduct(product);
      }
    }
  }
});
